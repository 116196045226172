import cookie from "react-cookies";

const queryString = require("query-string");

const defaultAccessToken = "your_access_token_from_quickreach";

export function getApiTokenQuery(isAnd) {
  let accessQuery = "";
  if (window.location.search) {
    const parsed = queryString.parse(window.location.search);
    if (parsed && parsed.access_token && parsed.access_token !== defaultAccessToken) {
      if (isAnd) accessQuery = `&api_token=${parsed.access_token}`;
      else accessQuery = `?api_token=${parsed.access_token}`;
    } else if (parsed && parsed.api_token) {
      if (isAnd) accessQuery = `&api_token=${parsed.api_token}`;
      else accessQuery = `?api_token=${parsed.api_token}`;
    }
  }
  return accessQuery;
}
export function getAccessTokenQuery(isAnd) {
  let accessQuery = "";
  if (window.location.search) {
    const parsed = queryString.parse(window.location.search);
    if (parsed && parsed.access_token && parsed.access_token.length > 0
      && parsed.access_token !== defaultAccessToken) {
      if (isAnd) accessQuery = `&access_token=${parsed.access_token}`;
      else accessQuery = `?access_token=${parsed.access_token}`;
    }
  }
  return accessQuery;
}
export function getToken() {
  if (!cookie.load("brewToken")) {
    if (window.location.search) {
      const parsed = queryString.parse(window.location.search);
      if (parsed && parsed.client_token && parsed.access_token.length > 0
        && parsed.access_token !== defaultAccessToken) return parsed.client_token;
      else return null;
    } else return null;
  } else return cookie.load("brewToken");
}
export function getLocationHref() {
  let retVal = "";
  if (window.location.href && window.location.href.length > 0) {
    const splits = window.location.href.split("://");
    if (splits && splits.length > 0) {
      retVal = `${splits[0]}://`;
    }
    if (splits && splits.length > 1) {
      const urlPart = splits[1];
      if (urlPart && urlPart.length > 0) {
        if (urlPart.indexOf("/") >= 0) {
          const partSplits = urlPart.split("/");
          if (partSplits && partSplits.length > 0) {
            retVal += partSplits[0];
          }
        } else retVal += urlPart;
      }
    }
  }
  return retVal;
}
export function hasAccessToken() {
  if (window.location.search) {
    const parsed = queryString.parse(window.location.search);
    if (parsed && parsed.access_token && parsed.access_token.length > 0) return true;
    else return false;
  } else return false;
}
export function getClientTokenQuery(isAnd, isAll) {
  let clientQuery = "";
  if (window.location.search && !cookie.load("brewToken")) {
    const parsed = queryString.parse(window.location.search);
    if (parsed) {
      if (isAnd) {
        if (parsed.client_token) {
          clientQuery = `&client_token=${parsed.client_token}`;
          if (isAll) {
            if (parsed.api_token) clientQuery += `&api_token=${parsed.api_token}`;
            else if (parsed.access_token && parsed.access_token !== defaultAccessToken) clientQuery += `&api_token=${parsed.access_token}`;
          }
        } else if (!parsed.client_token) {
          if (isAll) {
            if (parsed.api_token) clientQuery = `&api_token=${parsed.api_token}`;
            else if (parsed.access_token && parsed.access_token !== defaultAccessToken) clientQuery = `&api_token=${parsed.access_token}`;
          }
        }
      } else if (!isAnd) {
        if (parsed.client_token) clientQuery = `?client_token=${parsed.client_token}`;
        if (isAll) {
          if (clientQuery && clientQuery.length > 0) {
            if (parsed.api_token) clientQuery += `&api_token=${parsed.api_token}`;
            else if (parsed.access_token && parsed.access_token !== defaultAccessToken) clientQuery += `&api_token=${parsed.access_token}`;
          } else if (!clientQuery || clientQuery.length <= 0) {
            if (parsed.api_token) clientQuery += `?api_token=${parsed.api_token}`;
            else if (parsed.access_token && parsed.access_token !== defaultAccessToken) clientQuery += `?api_token=${parsed.access_token}`;
          }
        } else if (!isAll) {
          if (parsed.api_token) clientQuery = `?api_token=${parsed.api_token}`;
          else if (parsed.access_token && parsed.access_token !== defaultAccessToken) clientQuery = `?api_token=${parsed.api_token}`;
        }
      }
    }
  } else if (window.location.search && cookie.load("brewToken")) {
    const parsed = queryString.parse(window.location.search);
    if (parsed) {
      if (isAnd) {
        if (isAll) {
          if (parsed.api_token) clientQuery = `&api_token=${parsed.api_token}`;
          else if (parsed.access_token && parsed.access_token !== defaultAccessToken) clientQuery = `&api_token=${parsed.access_token}`;
        }
      } else if (!isAnd) {
        if (isAll) {
          if (clientQuery && clientQuery.length > 0) {
            if (parsed.api_token) clientQuery += `&api_token=${parsed.api_token}`;
            else if (parsed.access_token && parsed.access_token !== defaultAccessToken) clientQuery += `&api_token=${parsed.access_token}`;
          } else if (!clientQuery || clientQuery.length <= 0) {
            if (parsed.api_token) clientQuery += `?api_token=${parsed.api_token}`;
            else if (parsed.access_token && parsed.access_token !== defaultAccessToken) clientQuery += `?api_token=${parsed.access_token}`;
          }
        } else if (!isAll) {
          if (parsed.api_token) clientQuery = `?api_token=${parsed.api_token}`;
          else if (parsed.access_token && parsed.access_token !== defaultAccessToken) clientQuery = `?api_token=${parsed.api_token}`;
        }
      }
    }
  }
  return clientQuery;
}
export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
